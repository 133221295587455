import React, { useState, useEffect } from "react";
import axios from "axios";
import country from "country-list-js";
import countryCodes from "country-codes-list";
import { Typography, Button } from "../../../utils";
import { LocaleAsString } from "../../../../localeEngine";
import { Input, Select, GoogleAutocomplete } from "../../../forms";
import Icon from "../../../icons";
import { Validations } from "../../../forms/input/data";
import { CONNECT, CONNECT_CLIENT } from "../../../../utils/definitions";
import { getSession, setSession } from "../../../../utils/localStorage";
import * as styles from "./styles.module.scss";

interface FormData {
  firstName: string;
  lastName: string;
  prefix: string;
  phone: string;
  addressLine1: string;
  city: string;
  postalCode: string;
  country: string;
  password: string;
}

const EditProfile = ({ editCompleted }) => {
  const [form, setForm] = useState<FormData>({
    firstName: "",
    lastName: "",
    prefix: "",
    phone: "",
    addressLine1: "",
    city: "",
    postalCode: "",
    country: "",
    password: "",
  });
  const [autocompletedAddress, setAutocompletedAddress] = useState({});
  const [loading, setLoading] = useState(false);

  const countryNames = country
    .names()
    .map((country) => {
      return { value: country, label: country };
    })
    .sort((a, b) => a.value.localeCompare(b.value));

  const phone = countryCodes.customList(
    "countryCallingCode",
    "{countryNameEn}"
  );

  const countryCode = countryCodes.customList("countryNameEn", "{countryCode}");

  let dialingCodes = Object.keys(phone).map((code) => {
    return {
      value: `+${code}`,
      label: `+${code} (${phone[code]})`,
      flag: countryCode[phone[code]],
    };
  });

  useEffect(() => {
    if (Object.keys(autocompletedAddress).length === 0) return;

    const currentForm = { ...form, ...autocompletedAddress };

    setForm(currentForm);
  }, [autocompletedAddress]);

  useEffect(() => {
    const session = getSession();
    const customer = session.customer;
    const currentForm = { ...customer };
    setForm(currentForm);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!checkInput() && !loading) {
      editProfile();
    }
  };

  const handleChange = (name, event) => {
    const currentForm = { ...form };
    let value = event.target.value;
    if (name === "prefix") {
      if (value.indexOf("+") === -1) {
        value = "+" + value;
      }
    }
    currentForm[name] = value;
    setForm(currentForm);
  };

  const autoCompleteAddress = (place) => {
    const currentForm = {};

    let streetNumber = "";
    let route = "";

    place.address_components.forEach((component) => {
      if (!component.types.indexOf("country"))
        currentForm["country"] = component.long_name;
      if (!component.types.indexOf("locality"))
        currentForm["city"] = component.long_name;
      if (!component.types.indexOf("postal_town"))
        currentForm["city"] = component.long_name;
      if (!component.types.indexOf("postal_code"))
        currentForm["postalCode"] = component.long_name;
      if (!component.types.indexOf("street_number"))
        streetNumber = component.long_name;
      if (!component.types.indexOf("route")) route = component.long_name;
    });

    currentForm["addressLine1"] = `${route}${
      streetNumber ? ` ${streetNumber}` : ""
    }`;

    setAutocompletedAddress(currentForm);
  };

  const checkInput = () => {
    const {
      addressLine1,
      city,
      country,
      firstName,
      lastName,
      phone,
      postalCode,
      prefix,
    } = form;

    const phoneValidation = Validations[prefix] ? Validations[prefix] : true;
    const isPhoneValid =
      phoneValidation === true ? true : phoneValidation.test(phone);

    const postalCodeValidation = Validations[country]
      ? Validations[country]
      : true;
    const isPostalCodeValid =
      postalCodeValidation === true
        ? true
        : postalCodeValidation.test(postalCode);

    const userData = !(
      firstName &&
      lastName &&
      addressLine1 &&
      prefix &&
      phone &&
      isPhoneValid &&
      city &&
      postalCode &&
      isPostalCodeValid &&
      country
    );

    return userData;
  };

  const editProfile = () => {
    setLoading(true);
    axios
      .post(`${CONNECT}/checkout/customer-info`, {
        client: CONNECT_CLIENT,
        session: getSession().uuid,
        customer: { ...form },
      })
      .catch((e) => console.log("ERROR", e))
      .then((response) => {
        setLoading(false);
        if (response.data && response.data.customer) {
          setSession(response.data);
          editCompleted();
        }
      });
  };

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Icon icon="User" size="S" className={styles.icon} />
        <Typography component="span" variant="h500-medium">
          <LocaleAsString id="my_account.menu.details" />
        </Typography>
      </div>
      <form className={styles.form} onSubmit={onSubmit}>
        <Input
          className={styles.halfInput}
          label={<LocaleAsString id="checkout.guest_details.form.first_name" />}
          icon="User"
          required
          name="firstName"
          type="text"
          onChange={(e) => handleChange("firstName", e)}
          value={form.firstName}
        />
        <Input
          className={styles.halfInput}
          label={<LocaleAsString id="checkout.guest_details.form.last_name" />}
          icon="User"
          required
          name="lastName"
          type="text"
          onChange={(e) => handleChange("lastName", e)}
          value={form.lastName}
        />
        <Select
          className={styles.halfInput}
          name="prefix"
          label={<LocaleAsString id="checkout.guest_details.form.prefix" />}
          icon="Phone"
          onChange={(e) => handleChange("prefix", e)}
          value={form.prefix}
          options={dialingCodes}
        />
        <Input
          className={styles.halfInput}
          label={
            <LocaleAsString id="checkout.guest_details.form.phone_number" />
          }
          icon="Phone"
          required
          type="tel"
          name="phone"
          validation={form.prefix}
          onChange={(e) => handleChange("phone", e)}
          value={form.phone}
        />
        <GoogleAutocomplete
          className={styles.fullInput}
          onPlaceSelected={(place) => {
            autoCompleteAddress(place);
          }}
          options={{
            types: ["address"],
          }}
          label={<LocaleAsString id="checkout.guest_details.form.address" />}
          icon="Pin"
          required
          name="addressLine1"
          onChange={(e) => handleChange("addressLine1", e)}
          value={form.addressLine1}
        />
        <Input
          className={styles.halfInput}
          label={
            <LocaleAsString id="checkout.guest_details.form.postal_code" />
          }
          icon="Mailbox"
          required
          name="postalCode"
          validation={form.country}
          onChange={(e) => handleChange("postalCode", e)}
          value={form.postalCode}
        />
        <Input
          className={styles.halfInput}
          label={<LocaleAsString id="checkout.guest_details.form.city" />}
          icon="Buildings"
          required
          name="city"
          onChange={(e) => handleChange("city", e)}
          value={form.city}
        />
        <Select
          className={styles.fullInput}
          icon="Flag"
          options={countryNames}
          label={<LocaleAsString id="checkout.guest_details.form.country" />}
          required
          name="country"
          onChange={(e) => handleChange("country", e)}
          value={form.country}
        />
        <input type="submit" style={{ display: "none" }} />
      </form>
      <Button
        className={styles.cta}
        contentClassName={styles.ctaContent}
        onClick={() => editProfile()}
        disabled={checkInput()}
        loading={loading}
      >
        <Typography variant="h400-medium" component="span">
          <LocaleAsString id="my_account.details.save" />
        </Typography>
      </Button>
    </div>
  );
};

export default EditProfile;
