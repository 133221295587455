import * as styles from "./styles.module.scss";

const QuantityPickerClassName = (quantity, maxQuantity?, minQuantity?) => {
  const maxQuantityClassName = `${styles.quantityBtn} ${
    quantity < maxQuantity ? "" : styles.disabled
  }`;
  const minQuantityClassName = `${styles.quantityBtn} ${
    quantity > minQuantity ? "" : styles.disabled
  }`;
  return maxQuantity ? maxQuantityClassName : minQuantityClassName;
};
const updateQuantity = (quantity, minQuantity, maxQuantity, setQuantity) => {
  if (quantity >= minQuantity && quantity <= maxQuantity) {
    return setQuantity(quantity);
  }
  return;
};
export { QuantityPickerClassName, updateQuantity };
