import React from "react";
import Icon from "../icons";
import { Typography } from "../utils";
import { QuantityPickerClassName, updateQuantity } from "./logic";
import * as styles from "./styles.module.scss";

import QuantityTypes from "./types";

const QuantityPicker = ({
  quantity,
  setQuantity,
  maxQuantity,
  minQuantity,
  className,
}: QuantityTypes) => {
  const addQuantity = () =>
    updateQuantity(quantity + 1, minQuantity, maxQuantity, setQuantity);
  const substractQuantity = () =>
    updateQuantity(quantity - 1, minQuantity, maxQuantity, setQuantity);
  const maxQuantityClassName = QuantityPickerClassName(quantity, maxQuantity);
  const minQuantityClassName = QuantityPickerClassName(
    quantity,
    null,
    minQuantity
  );
  return (
    <div className={`${styles.quantityPicker} ${className}`}>
      <div
        className={minQuantityClassName}
        role="button"
        tabIndex={0}
        onClick={() => substractQuantity()}
        onKeyPress={() => substractQuantity()}
      >
        <Icon icon="Minus" size="Small" />
      </div>
      <Typography
        className={styles.quantity}
        variant="p-heavy"
        component="span"
      >
        {quantity}
      </Typography>
      <div
        className={maxQuantityClassName}
        role="button"
        tabIndex={0}
        onClick={() => addQuantity()}
        onKeyPress={() => addQuantity()}
      >
        <Icon icon="Plus" size="Small" />
      </div>
    </div>
  );
};

export default QuantityPicker;
