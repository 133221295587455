import React from "react";
import Skeleton from "react-loading-skeleton";

import * as styles from "../styles.module.scss";

const PassholdersSkeleton = () => {
  return (
    <div className={styles.passholders}>
      <div className={styles.section}>
        <span
          style={{ width: "100%", borderRadius: "16px", overflow: "hidden" }}
        >
          <Skeleton height={"4rem"} width={"100%"} />
        </span>
      </div>
      <div className={styles.section}>
        <span
          style={{ width: "100%", borderRadius: "16px", overflow: "hidden" }}
        >
          <Skeleton height={"16rem"} />
        </span>
      </div>
      <div className={styles.section}>
        <span
          style={{ width: "100%", borderRadius: "16px", overflow: "hidden" }}
        >
          <Skeleton height={"5.6rem"} />
        </span>
      </div>
    </div>
  );
};

export default PassholdersSkeleton;
