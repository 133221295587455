import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography, Button } from "../../../utils";
import Icon from "../../../icons";
import { LocaleAsString } from "../../../../localeEngine";
import { Input } from "../../../forms";
import Modal from "../../../modal";
import { CONNECT, CONNECT_CLIENT } from "../../../../utils/definitions";
import { getSession } from "../../../../utils/localStorage";
import * as styles from "./styles.module.scss";

const ChangePassword = ({ closeModalFn }) => {
  const [step, setStep] = useState(0);
  const [form, setForm] = useState({
    email: "",
    temporaryPassword: "",
    newPassword: "",
    newPassword2: "",
  });
  const [loading, setLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    const session = getSession();
    const email = session && session.customer && session.customer.email;
    if (email) {
      const currentForm = { ...form };
      currentForm.email = email;
      setForm(currentForm);
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (step === 0) return getTemporaryPassword();
    if (step === 1 && !checkInputs() && !successModal) return changePassword();
  };

  const handleChange = (name, event) => {
    const currentForm = { ...form };
    let value = event.target.value;
    currentForm[name] = value;

    setForm(currentForm);
  };

  const getTemporaryPassword = () => {
    setLoading(true);
    axios
      .post(`${CONNECT}/checkout/temporary-password`, {
        client: CONNECT_CLIENT,
        session: getSession().uuid,
        email: form.email,
      })
      .catch((e) => console.log("ERROR", e))
      .then(() => {
        setLoading(false);
        setStep(1);
      });
  };

  const changePassword = () => {
    setLoading(true);
    axios
      .post(`${CONNECT}/checkout/change-password`, {
        client: CONNECT_CLIENT,
        session: getSession().uuid,
        email: form.email,
        temporaryPassword: form.temporaryPassword,
        password: form.newPassword,
      })
      .catch((e) => console.log("ERROR", e))
      .then(() => {
        setLoading(false);
        setSuccessModal(true);
      });
  };

  const closeModal = () => {
    setSuccessModal(false);
    closeModalFn();
  };

  const checkInputs = () => {
    const { temporaryPassword, newPassword, newPassword2 } = form;
    return !(
      temporaryPassword &&
      newPassword &&
      newPassword2 &&
      newPassword === newPassword2
    );
  };

  return (
    <div className={styles.content}>
      <form className={styles.form} onSubmit={onSubmit}>
        <div className={styles.header}>
          <Icon icon="Lock" size="S" className={styles.icon} />
          <Typography component="span" variant="h500-medium">
            <LocaleAsString id="my_account.menu.password" />
          </Typography>
        </div>
        <input type="submit" style={{ display: "none" }} />
        {step === 0 ? (
          <>
            <Typography
              variant="h400-medium"
              component="span"
              className={styles.description}
            >
              <LocaleAsString id="my_account.password.request" />:{" "}
              <strong>{form.email}</strong>
            </Typography>
            <Button
              className={styles.cta}
              onClick={() => getTemporaryPassword()}
              loading={loading}
            >
              <Typography variant="h400-medium" component="span">
                <LocaleAsString id="my_account.password.request.button" />
              </Typography>
            </Button>
          </>
        ) : (
          <>
            <Typography
              variant="h400-medium"
              component={"p"}
              className={styles.description}
            >
              <LocaleAsString id="reset_password.form.submited_temporary_password" />{" "}
              <strong>{form.email}</strong>
            </Typography>
            <Typography
              variant="h400-medium"
              component={"p"}
              className={styles.description}
            >
              <LocaleAsString id="reset_password.form.submited_temporary_password_explain" />
            </Typography>
            <Input
              label={
                <LocaleAsString id="reset_password.form.temporary_password" />
              }
              icon="Clock"
              required
              name="temporaryPassword"
              type="text"
              onChange={(e) => handleChange("temporaryPassword", e)}
              value={form.temporaryPassword}
            />
            <Typography
              variant="h400-medium"
              component={"p"}
              className={styles.description}
            >
              <LocaleAsString id="reset_password.form.enter_a_new_password" />
            </Typography>
            <div className={styles.passwordLabels}>
              <Input
                label={<LocaleAsString id="reset_password.form.new_password" />}
                icon="Lock"
                required
                name="newPassword"
                type="password"
                onChange={(e) => handleChange("newPassword", e)}
                value={form.newPassword}
                autoComplete="new-password"
              />
              <Input
                label={
                  <LocaleAsString id="reset_password.form.repeat_new_password" />
                }
                icon="Lock"
                required
                name="newPassword2"
                type="password"
                repeatField={form.newPassword !== form.newPassword2}
                disablePaste={true}
                repeatError="repeatPassword"
                onChange={(e) => handleChange("newPassword2", e)}
                value={form.newPassword2}
                autoComplete="new-password"
              />
            </div>
            <Button
              className={styles.cta}
              onClick={() => changePassword()}
              loading={loading}
              disabled={checkInputs()}
            >
              <Typography variant="h400-medium" component="span">
                Change password
              </Typography>
            </Button>
          </>
        )}
        {successModal && (
          <Modal closeFn={() => closeModal()} isAlert={true} size="small">
            <div className={styles.successModal}>
              <Icon icon="CheckCircle" size="Medium" className={styles.icon} />
              <Typography variant="h400-heavy" component="span">
                <LocaleAsString id="my_account.password.changed" />
              </Typography>
              <Button onClick={() => closeModal()} className={styles.cta}>
                <Typography variant="h400-medium" component="span">
                  Ok
                </Typography>
              </Button>
            </div>
          </Modal>
        )}
      </form>
    </div>
  );
};

export default ChangePassword;
