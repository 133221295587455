import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { navigate } from "gatsby-link";
import Skeleton from "react-loading-skeleton";
import QRcode from "qrcode.react";
import {
  EditProfile,
  ChangePassword,
  Passholders,
  PassholdersSkeleton,
} from "../../components/pages/my-account";
import Layout from "../../components/layout";
import { Container, Typography, Button } from "../../components/utils";
import Icon from "../../components/icons";
import ProductCell from "../../components/checkout/productCell";
import LocaleAsString from "../../localeEngine/localeAsString";
import LogoutModal from "../../components/session/logoutModal";
import { MenuOptions } from "../../components/pages/my-account/data";
import Currency from "../../components/currency";
import Accordion from "../../components/accordion";
import { getSession, setSession } from "../../utils/localStorage";
import QS from "../../utils/queryStringRetriever";
import { CONNECT, CONNECT_CLIENT } from "../../utils/definitions";
import * as styles from "./styles.module.scss";

const isDefined =
  typeof window !== "undefined" || typeof document !== "undefined";

const updateUrl = (values) => {
  const query = QS();

  let url = new URL(window.location);

  Object.keys(values).forEach((value) => {
    const key = value;
    const keyValue = values[value];

    if (query[key] === keyValue) return;

    if (keyValue || keyValue === 0) {
      url.searchParams.set(key, keyValue);
    } else {
      url.searchParams.delete(key, keyValue);
    }
  });

  window.history.pushState({ values }, "", url);
};

const setDefaultPage = () => {
  if (isDefined) {
    if (window.innerWidth > 991) {
      return "orders";
    }
  }
  return "menu";
};

const MyAccountMenu = ({ page, setPageState, hideOnMobile, selectedOrder }) => {
  const [loading, setLoading] = useState(false);
  const [logoutModal, setLogoutModal] = useState(undefined);
  const [loadingLogout, setLoadingLogout] = useState(undefined);

  let session = getSession();

  if (typeof session === "undefined") {
    session = {
      customer: {
        firstName: "",
        lastName: "",
        email: "",
      },
      uuid: false,
    };
  }
  const firstName = session?.customer?.firstName || "";
  const lastName = session?.customer?.lastName || "";
  const email = session?.customer?.email || "";

  const handleClose = () => {
    if (loadingLogout) return;
    setLogoutModal(false);
  };

  const logoutFunc = () => {
    setLoadingLogout(true);
    axios
      .post(`${CONNECT}/checkout/logout`, {
        client: CONNECT_CLIENT,
        session: session.uuid,
      })
      .catch((e) => {
        setLoading(false);
        setLoadingLogout(false);
      })
      .then((response) => {
        if (response && response.data) {
          setSession(response.data);
        } else {
          const logoutSession = session;
          logoutSession.customer = {};
          setSession(logoutSession);
          setLoading(false);
        }
        setLoadingLogout(false);
        setLogoutModal(false);
        navigate("/");
      });
  };

  return (
    <>
      <Typography
        variant="h900-medium"
        component="p"
        className={styles.myAccountTitle}
      >
        <LocaleAsString id="my_account.menu.title" />
      </Typography>
      <GoBackButton
        page={page}
        setPageState={setPageState}
        logoutLoading={loading}
        logoutFunc={() => setLogoutModal(true)}
        selectedOrder={selectedOrder}
        hideOnMobile={page === "menu"}
      />
      {logoutModal && (
        <LogoutModal
          closeFn={handleClose}
          logout={logoutFunc}
          loadingLogout={loadingLogout}
        />
      )}
      <div className={`${styles.menu} ${hideOnMobile && styles.hideMobile}`}>
        <section className={`${styles.section} ${styles.profile}`}>
          <picture className={styles.avatar}>
            {false ? (
              <img
                alt="our beautiful customer"
                src="https://p0.pxfuel.com/preview/103/842/392/human-portrait-nature-focus.jpg"
              />
            ) : (
              <Typography
                variant="h800-heavy"
                component="span"
                className={styles.initials}
              >
                {firstName.charAt(0).toUpperCase()}
                {lastName.charAt(0).toUpperCase()}
              </Typography>
            )}
          </picture>
          <p>
            <Typography
              component="span"
              variant="h500-medium"
              className={styles.name}
            >
              {`${firstName}${lastName ? ` ${lastName}` : ""}`}
            </Typography>
            <br />
            <Typography
              component="span"
              variant="h400-medium"
              className={styles.email}
            >
              {email}
            </Typography>
          </p>
        </section>
        <section className={`${styles.section} ${styles.options}`}>
          <div className={styles.header}>
            <Typography component="p" variant="h500-medium">
              <LocaleAsString id="my_account.menu.title" />
            </Typography>
          </div>

          {MenuOptions.map((item, idx) => {
            let isSelected = page.toLowerCase() === item.action.toLowerCase();
            if (
              page.toLowerCase() === "menu" &&
              item.action.toLowerCase() === "orders"
            ) {
              isSelected = true;
            }

            if (
              page.toLowerCase() === "passholders" &&
              item.action.toLowerCase() === "orders"
            ) {
              isSelected = true;
            }

            return (
              <Button
                className={`${styles.option} ${
                  isSelected && styles.isSelected
                }`}
                contentClassName={styles.optionContent}
                onClick={setPageState[item.action]}
                key={`menu-option_button_${idx}`}
              >
                <Icon icon={item.icon} size="S" className={styles.icon} />
                <Typography
                  component="span"
                  variant="h400-medium"
                  className={styles.label}
                >
                  <LocaleAsString id={item.label} />
                </Typography>
                <span className={styles.iconRight}>
                  <Icon icon="ChevronRight" size="S" />
                </span>
              </Button>
            );
          })}
        </section>
      </div>
    </>
  );
};

const MyAccountOrder = ({
  filteredOrders,
  hideOnMobile,
  selectedOrder,
  setPageState,
  loading,
}) => {
  const isOrderSelected = Object.keys(selectedOrder).length > 0;

  const customer = selectedOrder.customer;

  const qrCode =
    selectedOrder &&
    selectedOrder.sync &&
    selectedOrder.sync.status === true &&
    selectedOrder.sync.response &&
    selectedOrder.sync.response.ConfirmationNumber;

  return (
    <section
      className={`${styles.orders} ${
        hideOnMobile ? styles.hideMobile : undefined
      }`}
    >
      {isOrderSelected ? (
        <>
          <div className={`${styles.section} ${styles.productsListHeader}`}>
            <div className={styles.headerBackButton}>
              <Button
                onClick={() => setPageState["selectedOrder"]("")}
                className={styles.iconBack}
              >
                <Icon icon="ArrowLeft" size="S" />
              </Button>
            </div>
            <div className={styles.orderRowContent}>
              <div className={styles.priceWrapper}>
                <div className={styles.idDateWrapper}>
                  <Typography variant="h500-heavy" component="p">
                    <LocaleAsString id="my_account.menu.order" />#
                    {selectedOrder.id}
                  </Typography>
                </div>
                <Typography
                  variant="h400-medium"
                  component="span"
                  className={styles.date}
                >
                  {selectedOrder.formatDate}
                </Typography>
              </div>
              <div className={styles.priceOrder}>
                <Currency
                  price={selectedOrder.orderTotalPrice}
                  currency={selectedOrder.orderCurrency}
                  template="myAccountOrderTotal"
                  className={styles.price}
                />
              </div>
            </div>

            {qrCode && (
              <>
                <div className={styles.qrCodeWrapper}>
                  <Typography variant="h400-medium" component="span">
                    <LocaleAsString id="my_account.menu.order.qrDescription" />
                  </Typography>
                </div>
                <div className={styles.qrCodeWrapper}>
                  <Typography variant="h400-medium" component="span">
                    <LocaleAsString id="my_account.menu.order.qrDescriptionEmail" />
                  </Typography>
                </div>
                <div className={styles.qrCodeWrapper}>
                  <QRcode
                    value={qrCode}
                    renderAs="svg"
                    className={styles.qrCode}
                  />
                </div>
              </>
            )}
          </div>
          <div className={`${styles.section} ${styles.productsList}`}>
            <div className={styles.listHeader}>
              <span className={styles.icon}>
                <Icon icon="GuestCard" size="S" />
              </span>
              <Typography
                variant="h500-medium"
                component="p"
                className={styles.title}
              >
                <LocaleAsString id="my_account.menu.order.seasonpass" />
              </Typography>
            </div>
            {selectedOrder.products.map((product, idx) => {
              const missingPassholders = !product.fulfilled;

              const missingPassholdersAction = () =>
                missingPassholders && setPageState["goToPassholders"](idx);

              return (
                <Button
                  className={styles.producWrapperReset}
                  contentClassName={`${styles.productWrapper} ${
                    missingPassholders ? styles.missingPassholders : undefined
                  }`}
                  onClick={() => missingPassholdersAction()}
                  style={!missingPassholders ? { cursor: "default" } : null}
                  key={`order_products_${idx}`}
                >
                  <div className={styles.product}>
                    <ProductCell
                      product={product}
                      missingPassholders={missingPassholders}
                    />
                  </div>
                  {missingPassholders && (
                    <span className={styles.orderAlert}>
                      <Icon
                        icon="AlertTriangle"
                        size="Small"
                        className={styles.orderAlertIcon}
                      />
                      <Typography
                        variant="h300-medium"
                        component="span"
                        className={styles.orderAlertLabel}
                      >
                        <LocaleAsString id="my_account.menu.order.missing.passholder" />
                      </Typography>
                    </span>
                  )}
                </Button>
              );
            })}
          </div>
          <div className={`${styles.section} ${styles.productsList}`}>
            <Typography
              variant="h500-medium"
              component="p"
              className={styles.title}
            >
              <LocaleAsString id="my_account.menu.details" />
            </Typography>
            <div className={styles.guestsWrapper}>
              <Guest
                icon={"User"}
              >{`${customer.firstName} ${customer.lastName}`}</Guest>
              <Guest icon={"Email"}>{customer.email}</Guest>
              <Guest
                icon={"Phone"}
              >{`${customer.prefix} ${customer.phone}`}</Guest>
              <Guest icon={"Pin"}>{customer.addressLine1}</Guest>
              <Guest icon={"Mailbox"}>{customer.postalCode}</Guest>
              <Guest icon={"Town"}>{customer.city}</Guest>
              <Guest icon={"Flag"}>{customer.country}</Guest>
            </div>
          </div>
        </>
      ) : (
        <div className={`${styles.section} ${styles.ordersWrapper}`}>
          <div className={styles.ordersHeader}>
            <Icon icon="File" size="S" className={styles.icon} />
            <Typography variant="h500-medium" component="p">
              <LocaleAsString id="my_account.menu.orders" />
            </Typography>
          </div>
          {loading && (
            <>
              {[0, 1, 2, 3, 4].map((idx) => {
                return (
                  <Skeleton
                    height={"9.5rem"}
                    className={styles.orderRowSkeleton}
                    key={`order-skeleton-row-${idx}`}
                  />
                );
              })}
            </>
          )}
          {filteredOrders &&
            filteredOrders.map((order, idx) => {
              const products = order.products;

              let orderTotalPrice = 0;
              let orderCurrency = "";

              let missingPassholders = order.fulfillmentStatus !== "completed";

              products.forEach((product, idx) => {
                const unitPrice = product.attributeList.find(
                  (attribute) => attribute.attribute === "unitPrice"
                );
                if (idx === 0)
                  orderCurrency = product.attributeList.find(
                    (attribute) => attribute.attribute === "currency"
                  ).value;

                if (unitPrice && unitPrice.value) {
                  orderTotalPrice +=
                    parseFloat(unitPrice.value) * parseInt(product.quantity);
                }

                if (product.bundleSettings && product.bundleSettings.active) {
                  const bundles = product.bundleProducts;

                  bundles.forEach((bundle) => {
                    const unitPrice = parseFloat(bundle.unitPrice) | 0;
                    const quantity = parseInt(bundle.quantity) | 0;
                    orderTotalPrice += unitPrice * quantity;
                  });
                }
              });

              const formatDate = moment
                .utc(order.created_at)
                .format("MMM D, YYYY");
              const selectedorder = { ...order };
              selectedorder.orderTotalPrice = orderTotalPrice;
              selectedorder.formatDate = formatDate;
              selectedorder.orderCurrency = orderCurrency;

              const missingPassholdersProps = {};
              if (missingPassholders) {
                missingPassholdersProps.className = styles.missingPassholders;
                missingPassholdersProps.onClick = () =>
                  setPageState["selectedOrder"](order.id);
                missingPassholdersProps.role = "button";
                missingPassholdersProps.tabIndex = "-1";
              }

              return (
                <div
                  key={`filtered-orders_${idx}`}
                  {...missingPassholdersProps}
                >
                  <Button
                    onClick={() =>
                      !missingPassholders
                        ? setPageState["selectedOrder"](order.id)
                        : undefined
                    }
                    className={styles.orderRow}
                    contentClassName={styles.orderRowContent}
                  >
                    <div className={styles.priceWrapper}>
                      <Typography
                        variant="h400-medium"
                        component="span"
                        className={styles.idOrder}
                      >
                        <LocaleAsString id="my_account.menu.order" />#{order.id}
                      </Typography>
                      <Typography
                        variant="h300-medium"
                        component="span"
                        className={styles.dateOrder}
                      >
                        {formatDate}
                      </Typography>
                    </div>
                    <div className={styles.priceOrder}>
                      <Currency
                        price={orderTotalPrice}
                        currency={orderCurrency}
                        template="myAccountOrder"
                      />
                      <Icon icon="ChevronRight" size="S" />
                    </div>
                  </Button>
                  {missingPassholders && (
                    <span className={styles.orderAlert}>
                      <Icon
                        icon="AlertTriangle"
                        size="Small"
                        className={styles.orderAlertIcon}
                      />
                      <Typography
                        variant="h300-medium"
                        component="span"
                        className={styles.orderAlertLabel}
                      >
                        <LocaleAsString id="my_account.menu.order.missing.passholder" />
                      </Typography>
                    </span>
                  )}
                </div>
              );
            })}
        </div>
      )}
    </section>
  );
};

const MyAccountPassholders = ({
  selectedOrder,
  productIdx,
  assignPassholdersCallback,
  goBackFn,
}) => {
  if (
    (!productIdx && productIdx !== 0) ||
    Object.keys(selectedOrder).length === 0
  ) {
    return <PassholdersSkeleton />;
  }
  return (
    <Passholders
      selectedOrder={selectedOrder}
      productIdx={productIdx}
      assignPassholdersCallback={assignPassholdersCallback}
      goBackFn={goBackFn}
    />
  );
};

const Guest = ({ icon, children }) => {
  return (
    <div className={styles.guestRow}>
      <Icon icon={icon} size="S" className={styles.iconRow} />
      <Typography variant="h400-medium" component="p">
        {children}
      </Typography>
    </div>
  );
};

const GoBackButton = ({
  page,
  setPageState,
  logoutLoading,
  logoutFunc,
  selectedOrder,
  hideOnMobile = false,
}) => {
  const buttonAction = () => {
    if (page === "guestDetails" || page === "changePassword") {
      setPageState["menu"]();
    }
    if (page === "orders") {
      const isAnyOrderSelected = Object.keys(selectedOrder).length > 0;
      if (isAnyOrderSelected) {
        setPageState["selectedOrder"]("");
      } else {
        setPageState["menu"]();
      }
    }
    if (page === "passholders") {
      setPageState["passholdersGoBack"](-1);
    }
  };

  return (
    <div className={styles.myAccountCTA}>
      <Button
        onClick={() => buttonAction()}
        className={`${styles.iconBack} ${hideOnMobile && styles.hideMobile}`}
      >
        <Icon icon="ArrowLeft" size="S" />
      </Button>
      <Button
        className={styles.buttonLogout}
        contentClassName={styles.buttonLogoutContent}
        onClick={() => logoutFunc()}
        loading={logoutLoading}
      >
        <Icon icon="Logout" size="S" />
        <Typography variant="h400-medium" component="span">
          <LocaleAsString id="my_account.menu.logout" />
        </Typography>
      </Button>
    </div>
  );
};

const MyAccountChangePassword = ({ setPageState }) => {
  return (
    <section className={styles.section}>
      <ChangePassword closeModalFn={() => setPageState["menu"]()} />
    </section>
  );
};

const MyAccountGuestDetails = ({ setPageState }) => {
  return (
    <section className={styles.section}>
      <EditProfile editCompleted={() => setPageState["menu"]()} />
    </section>
  );
};

const MyAccount = () => {
  const [page, setPage] = useState(setDefaultPage());
  // "menu", "orders", "guestDetails", "changePassword", "passholders"
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [productIdx, setProductIdx] = useState(false);
  const [missingPassholders, setMissingPassholders] = useState(false);
  const [loading, setLoading] = useState(true);

  const search = isDefined && window.location.search;

  const setPageState = {
    menu: () => {
      updateUrl({ section: "menu" });
      setPage("menu");
    },
    orders: () => {
      updateUrl({ section: "orders" });
      setPage("orders");
      if (Object.keys(selectedOrder).length > 0) setSelectedOrder({});
    },
    guestdetails: () => {
      updateUrl({ section: "guestDetails" });
      setPage("guestDetails");
    },
    changepassword: () => {
      updateUrl({ section: "changePassword" });
      setPage("changePassword");
    },
    selectedOrder: (orderId) => {
      updateUrl({ orderId: orderId ? orderId : -1 });
      const order = filteredOrders.find((order) => order.id === orderId);
      if (!order) return setSelectedOrder({});
      prepareAndSetSeletectedOrder(order);
    },
    passholders: () => {
      updateUrl({ section: "passholders" });
      setPage("passholders");
    },
    productIdx: (productId) => {
      updateUrl({ productId: productId });
      setProductIdx(productId);
    },
    passholdersGoBack: () => {
      updateUrl({
        section: "orders",
        productId: -1,
      });
      setPage("orders");
      setProductIdx(false);
    },
    goToOrders: (orderId) => {
      updateUrl({
        section: "orders",
        orderId: orderId || orderId === 0 ? orderId : -1,
      });
      setPage("orders");
      const order = filteredOrders.find((order) => order.id === orderId);
      if (!order) return setSelectedOrder({});
      prepareAndSetSeletectedOrder(order);
    },
    goToPassholders: (productId) => {
      updateUrl({
        section: "passholders",
        productId: productId,
      });
      setPage("passholders");
      setProductIdx(productId);
    },
  };

  useEffect(() => {
    if (isDefined) {
      const { section } = QS();

      if (section) return;

      if (window.innerWidth >= 991) setPageState["orders"]();
      else setPageState["menu"]();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    popstateEvent();
    // eslint-disable-next-line
  }, [search]);

  const getCustomerOrders = (redirect = false) => {
    async function setCustomerOrders() {
      setLoading(true);
      const orders = await getCustomerOrder();
      orders.sort(function (a, b) {
        return -(a.id - b.id);
      });
      setFilteredOrders(orders);
      setLoading(false);
      if (redirect) redirectToQueryStringOrderId(orders);
    }

    setCustomerOrders();
  };

  const redirectToQueryStringOrderId = (orders) => {
    const { orderId } = QS();
    if (orderId) {
      const orderExistInFilteredOrders = orders.find(
        (filt) => filt.id === orderId
      );
      if (orderExistInFilteredOrders)
        prepareAndSetSeletectedOrder(orderExistInFilteredOrders);
    }
  };

  const popstateEvent = (e) => {
    const { section, orderId, productId } = QS();

    if (section === "passholders") {
      if (!orderId || orderId === 0) setPageState["orders"]();
      if (!productId && productId !== 0) {
        setPageState["goToOrders"](orderId);
      }
    }

    if (section) setPage(section);

    if (orderId === -1) {
      setSelectedOrder({});
    } else {
      const order = filteredOrders.find((order) => order.id === orderId);
      if (order) {
        prepareAndSetSeletectedOrder(order);
      } else {
        setSelectedOrder({});
      }
    }

    if (productId || productId === 0) {
      setProductIdx(productId);
    } else if (!productId || productId === -1) {
      setProductIdx(false);
    }
  };

  useEffect(() => {
    window.addEventListener("updatedOrders", getCustomerOrders);
    window.addEventListener("popstate", (e) => popstateEvent(e));
    return () => {
      window.removeEventListener("updatedOrders", getCustomerOrders);
      window.removeEventListener("popstate", popstateEvent);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!session || !session.customer) return;
    getCustomerOrders(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page, selectedOrder]);

  useEffect(() => {
    let passholderUnFulfilled = false;
    filteredOrders.forEach((orders) => {
      if (orders.fulfillmentStatus !== "completed")
        passholderUnFulfilled = true;
    });
    setMissingPassholders(passholderUnFulfilled);
  }, [filteredOrders]);

  useEffect(() => {
    if (Object.keys(selectedOrder).length > 0) {
      const { id } = selectedOrder;
      const updatedSelectedOrder = filteredOrders.find(
        (order) => order.id === id
      );

      prepareAndSetSeletectedOrder(updatedSelectedOrder);
    }
    // eslint-disable-next-line
  }, [filteredOrders]);

  const prepareAndSetSeletectedOrder = (order) => {
    const products = order.products;
    let orderTotalPrice = 0;
    let orderCurrency = "";

    products.forEach((product, idx) => {
      const unitPrice = product.attributeList.find(
        (attribute) => attribute.attribute === "unitPrice"
      );
      if (idx === 0)
        orderCurrency = product.attributeList.find(
          (attribute) => attribute.attribute === "currency"
        ).value;

      if (unitPrice && unitPrice.value) {
        orderTotalPrice +=
          parseFloat(unitPrice.value) * parseInt(product.quantity);
      }

      if (product.bundleSettings && product.bundleSettings.active) {
        const bundles = product.bundleProducts;

        bundles.forEach((bundle) => {
          const unitPrice = parseFloat(bundle.unitPrice) | 0;
          const quantity = parseInt(bundle.quantity) | 0;
          orderTotalPrice += unitPrice * quantity;
        });
      }
    });

    const formatDate = moment.utc(order.created_at).format("MMM D, YYYY");
    const selectedorder = { ...order };
    selectedorder.orderTotalPrice = orderTotalPrice;
    selectedorder.formatDate = formatDate;
    selectedorder.orderCurrency = orderCurrency;

    setSelectedOrder(selectedorder);
  };

  const session = getSession();

  if (!session || !session.customer) {
    if (isDefined) {
      navigate("/");
    }
    return null;
  }

  const getCustomerOrder = async () => {
    return await axios
      .post(`${CONNECT}/checkout/account/sessions`, {
        client: CONNECT_CLIENT,
        session: session.uuid,
      })
      .catch((e) => {
        console.log("error fetching customer orders", e);
        setLoading(false);
        return [];
      })
      .then((response) => {
        const orders = response.data;
        let filteredOrders = [];
        Array.isArray(orders) &&
          orders.forEach((order) => {
            const products = order.products;
            if (
              products &&
              Array.isArray(products) &&
              products.length > 0 &&
              order.status === "completed"
            )
              filteredOrders.push(order);
          });
        return filteredOrders;
      });
  };

  const PassHoldersGoBackFn = () => {
    setPageState["passholdersGoBack"]();
  };

  const goToOrder = () => {
    setPageState["goToOrders"]();
  };

  return (
    <Layout
      metatags={{ title: LocaleAsString({ id: "my_account.menu.title" }) }}
      backgroundColor={"#EFEFF4"}
      hideMobileFooter={true}
    >
      <Container maxWidth="lg" component="div" className={styles.toastWrapper}>
        <Button
          onClick={() => goToOrder()}
          className={styles.toastCTA}
          contentClassName={styles.fullWidth}
        >
          <Accordion open={missingPassholders} className={styles.fullWidth}>
            <div className={styles.toast}>
              <Icon
                icon="AlertTriangle"
                size="Small"
                className={styles.alert}
              />
              <Typography
                variant="h300-medium"
                component="span"
                className={styles.alertLabel}
              >
                <LocaleAsString id="my_account.menu.order.missing.passholder.one" />
              </Typography>
              <div
                className={`${styles.iconBackToast} ${
                  (page === "orders" || page === "menu") &&
                  Object.keys(selectedOrder).length === 0 &&
                  styles.hidden
                }`}
              >
                <Icon icon="ArrowRight" size="S" />
              </div>
            </div>
          </Accordion>
        </Button>
      </Container>
      <Container
        maxWidth="lg"
        component="div"
        className={styles.myAccountLayout}
      >
        <MyAccountMenu
          page={page}
          setPageState={setPageState}
          hideOnMobile={page !== "menu"}
          selectedOrder={selectedOrder}
        />
        {page === "menu" || page === "orders" ? (
          <MyAccountOrder
            filteredOrders={filteredOrders}
            selectedOrder={selectedOrder}
            setPageState={setPageState}
            hideOnMobile={page !== "orders"}
            loading={loading}
          />
        ) : null}
        {page === "passholders" ? (
          <MyAccountPassholders
            selectedOrder={selectedOrder}
            productIdx={productIdx}
            assignPassholdersCallback={() => PassHoldersGoBackFn()}
            goBackFn={() => PassHoldersGoBackFn()}
          />
        ) : null}
        {page === "guestDetails" ? (
          <MyAccountGuestDetails setPageState={setPageState} />
        ) : null}
        {page === "changePassword" ? (
          <MyAccountChangePassword setPageState={setPageState} />
        ) : null}
      </Container>
    </Layout>
  );
};

export default MyAccount;
