import React, { useEffect, useState } from "react";
import axios from "axios";
import { Typography, Button } from "../../../utils";
import QuantityPicker from "../../../quantityPicker";
import Passholder from "./passholder";
import Modal from "../../../modal";
import Icon from "../../../icons";
import Currency from "../../../currency";
import { CONNECT, CONNECT_CLIENT } from "../../../../utils/definitions";
import { getSession } from "../../../../utils/localStorage";
import { LocaleAsString } from "../../../../localeEngine";
import * as styles from "./styles.module.scss";

const Passholders = ({
  selectedOrder,
  productIdx,
  assignPassholdersCallback,
  goBackFn,
}) => {
  const [hasAdults, setHasAdults] = useState(false);
  const [adults, setAdults] = useState(0);
  const [hasChildren, setHasChildren] = useState(false);
  const [children, setChildren] = useState(0);
  const [passholders, setPassholders] = useState([]);
  const [savedPassholders, setSavedPassholders] = useState([]);
  const [focus, setFocus] = useState(0);
  const [friendsAndFamily, setFriendsAndFamily] = useState([]);
  const [assignModal, setAssignModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [errorImage, setErrorImage] = useState("");

  const product = selectedOrder.products[productIdx];
  const requirements = product.requirements;

  useEffect(() => {
    getFriendsAndFamily();
    if (product.type && product.type === "isAdult") {
      setHasAdults({
        maxQuantity: (product.maxAdult || 1) * product.quantity,
        minQuantity: (product.minAdult || 1) * product.quantity,
      });
      setAdults((product.minAdult || 1) * product.quantity);
    }

    if (product.type && product.type === "isJunior") {
      setHasChildren({
        maxQuantity: (product.maxJunior || 1) * product.quantity,
        minQuantity: (product.minJunior || 1) * product.quantity,
      });
      setChildren((product.minJunior || 1) * product.quantity);
    }

    if (product.type && product.type === "isBundle") {
      setHasAdults({
        maxQuantity: (product.maxAdult || 1) * product.quantity,
        minQuantity: (product.minAdult || 1) * product.quantity,
      });
      setHasChildren({
        maxQuantity: (product.maxJunior || 1) * product.quantity,
        minQuantity: (product.minJunior || 1) * product.quantity,
      });
      setAdults((product.minAdult || 1) * product.quantity);
      setChildren((product.minJunior || 1) * product.quantity);
    }
    // eslint-disable-next-line
  }, []);

  const getFriendsAndFamily = () => {
    axios
      .post(`${CONNECT}/checkout/account/friends-and-family`, {
        client: CONNECT_CLIENT,
        session: getSession().uuid,
      })
      .then((response) => {
        if (response && response.data) {
          setFriendsAndFamily(response.data);
        }
      });
  };

  const getRequirements = (ageCategory) => {
    let requirementsToReturn = [];
    requirements.forEach((req) => {
      if (
        (req.identifier.indexOf("adult") > -1 && ageCategory !== "adult") ||
        (req.identifier.indexOf("junior") > -1 && ageCategory !== "junior")
      ) {
        return;
      }
      requirementsToReturn.push({
        created_at: req.created_at,
        id: req.id,
        identifier: req.identifier,
        locale: req.locale,
        maxValue: req.maxValue,
        minValue: req.minValue,
        name: req.name,
        required: req.required,
        type: req.type,
        updated_at: req.updated_at,
      });
    });

    return requirementsToReturn;
  };

  useEffect(() => {
    const updatePassholders = [...passholders];
    const currentAdults =
      updatePassholders.filter((passholder) => passholder.type === "isAdult")
        ?.length || 0;
    const currentChildren =
      updatePassholders.filter((passholder) => passholder.type === "isJunior")
        ?.length || 0;

    if (currentAdults < adults) {
      for (let index = 0; index < adults - currentAdults; index++) {
        updatePassholders.push({
          title: (
            <>
              <LocaleAsString id="my_account.passholders.assigned.adult" />{" "}
              {currentAdults + 1}
            </>
          ),
          id: updatePassholders.length,
          guestId: updatePassholders.length + 1,
          type: "isAdult",
          requirements: getRequirements("adult"),
        });
      }
    }

    if (currentChildren < children) {
      for (let index = 0; index < children - currentChildren; index++) {
        updatePassholders.push({
          title: (
            <>
              <LocaleAsString id="my_account.passholders.assigned.junior" />{" "}
              {currentChildren + 1}
            </>
          ),
          id: updatePassholders.length,
          guestId: updatePassholders.length + 1,
          type: "isJunior",
          requirements: getRequirements("junior"),
        });
      }
    }

    if (currentChildren > children) {
      let lastChildrenIndex = undefined;
      for (let index = updatePassholders.length - 1; index > -1; index--) {
        if (updatePassholders[index].type === "isJunior") {
          lastChildrenIndex = index;
          updatePassholders.splice(lastChildrenIndex, 1);
          break;
        }
      }
    }

    if (currentAdults > adults) {
      let lastAdultIndex = undefined;
      for (let index = updatePassholders.length - 1; index > -1; index--) {
        if (updatePassholders[index].type === "isAdult") {
          lastAdultIndex = index;
          updatePassholders.splice(lastAdultIndex, 1);
          break;
        }
      }
    }

    setPassholders(updatePassholders);
    // eslint-disable-next-line
  }, [adults, children]);

  const handleChange = (name, event, id, isFile = false) => {
    const currentPassholders = [...passholders];
    let value = "";
    const positionOfRequirement = requirements.indexOf(
      requirements.find((req) => req.identifier === name)
    );

    if (!event.target) {
      value = event;
      if (positionOfRequirement > -1) {
        currentPassholders[id].requirements[positionOfRequirement].value =
          value;
        setPassholders(currentPassholders);
      }
      if (name === "originalId") currentPassholders[id].originalId = value;
      return;
    }

    if (isFile) {
      if (event.target.files && event.target.files[0]) {
        setLoadingImage(true);

        const availableImageType = [
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/webp",
        ];
        const dd = new FormData();

        const fileSizeInMB = event.target.files[0].size / 1024 / 1024;
        const fileType = event.target.files[0].type;
        const isTypeValid = availableImageType.some(
          (available) => available === fileType
        );

        const tiemstamp = new Date().getTime();
        const sessionId = getSession().uuid;

        const originalFileName = event.target.files[0].name;

        const dotIndex = originalFileName.lastIndexOf(".");
        const fileExtension = originalFileName.substring(dotIndex);

        const fileName = `${sessionId}-${tiemstamp}${fileExtension}`;

        const renamedFile = new File([event.target.files[0]], fileName);

        dd.append(renamedFile.name, renamedFile);

        if (fileSizeInMB > 8) {
          setErrorImage("errorSize");
          return setLoadingImage(false);
        } else if (!isTypeValid) {
          setErrorImage("errorFormat");
          return setLoadingImage(false);
        } else {
          setErrorImage("");
        }

        axios
          .post("https://connect3-dev.spotlio.com/S3Upload", dd, {
            "Content-Type": "multipart/form-data",
          })
          .catch(() => setLoadingImage(false))
          .then((res) => {
            if (res.status === 200) {
              const status = res.data && res.data.status[0];
              currentPassholders[id].requirements[positionOfRequirement].value =
                status;
              setPassholders(currentPassholders);
              setLoadingImage(false);
            }
          });
      }

      value = event.target.files[0];
    } else {
      value = event.target.value || "";
      if (value === "error") {
        currentPassholders[id].requirements[positionOfRequirement].error = true;
        setPassholders(currentPassholders);
        return;
      }
      if (positionOfRequirement > -1) {
        currentPassholders[id].requirements[positionOfRequirement].value =
          value;
        currentPassholders[id].requirements[
          positionOfRequirement
        ].error = false;
        setPassholders(currentPassholders);
      }
      if (name === "saved") {
        setFocus(id + 1);
        let currentSaved = [...savedPassholders];
        const currentIdx = savedPassholders.findIndex(
          (saved) => saved.id === id
        );

        if (currentIdx > -1) {
          currentSaved.splice(currentIdx, 1);
        }

        let newSaved = {
          title: currentPassholders[id].title,
          id: id,
          guestId: currentPassholders[id].guestId,
          type: currentPassholders[id].type,
          requirements: [],
        };

        currentPassholders[id].requirements?.forEach((req) => {
          let newRequirement = {};
          Object.keys(req).forEach((key) => {
            newRequirement[key] = req[key];
          });
          newSaved.requirements.push(newRequirement);
        });

        currentSaved.push(newSaved);
        setSavedPassholders(currentSaved);
        updateOrCreateFriendsAndFamily(currentPassholders[id]);
      }
    }
  };

  const updateOrCreateFriendsAndFamily = (passholder) => {
    let parsedRequirements = [];
    passholder.requirements.forEach((req) => {
      let requirement = {
        identifier: req.identifier,
        value: req.value,
      };
      if (passholder.originalId) {
        requirement.id = undefined;
        friendsAndFamily.forEach((faf) => {
          if (faf.id === passholder.originalId) {
            faf.requirements.forEach((requir) => {
              if (requir.identifier === req.identifier) {
                requirement.id = requir.id;
              }
            });
          }
        });
      }
      parsedRequirements.push(requirement);
    });

    let item = {
      firstName: passholder.requirements.find(
        (req) => req.identifier === "first-name-required"
      )?.value,
      lastName: passholder.requirements.find(
        (req) => req.identifier === "last-name-required"
      )?.value,
      requirements: parsedRequirements,
    };

    if (passholder.originalId) {
      item.id = passholder.originalId;
    }

    axios
      .post(`${CONNECT}/checkout/account/update-friends-and-family`, {
        client: CONNECT_CLIENT,
        session: getSession().uuid,
        item: { ...item },
      })
      .then((res) => {
        if (res && res.data) {
          let faf = friendsAndFamily;
          if (passholder.originalId) {
            faf[faf.indexOf(faf.find((f) => f.id === passholder.originalId))] =
              res.data;
          } else {
            faf.push(res.data);
          }
          setFriendsAndFamily(faf);
        }
      });
  };

  const checkPassholders = () => {
    let isDisabled = false;
    if (savedPassholders.length !== passholders.length) {
      isDisabled = true;
    }

    return isDisabled;
  };

  const assignPassholderModal = () => {
    setAssignModal(true);
  };

  const closeModalFunction = () => {
    setAssignModal(false);
    if (successModal) assignPassholdersCallback();
  };

  const successPassholderModal = () => {
    assignPassholders();
  };

  const assignPassholders = () => {
    const { itemId } = product;
    const APIRequirements = [];

    savedPassholders.forEach((holder) => {
      let guest = {
        guest: holder.guestId,
        requirements: [],
        type: holder.type,
      };
      holder.requirements.forEach((req) => {
        guest.requirements.push({
          identifier: req.identifier,
          value: req.value,
        });
      });
      APIRequirements.push(guest);
    });

    setAssignLoading(true);

    axios
      .post(`${CONNECT}/checkout/account/update-itinerary-requirements`, {
        client: CONNECT_CLIENT,
        session: selectedOrder.uuid,
        requirements: {
          itemId: itemId,
          requirements: APIRequirements,
        },
      })
      .then((res) => {
        if (typeof window !== "undefined") {
          const event = new Event("updatedOrders");
          window.dispatchEvent(event);
        }
        setSuccessModal(true);
        setAssignLoading(false);
      });
  };

  return (
    <>
      <div className={styles.passholders}>
        <div className={`${styles.section} ${styles.productsListHeader}`}>
          <div className={styles.headerBackButton}>
            <Button onClick={() => goBackFn()} className={styles.iconBack}>
              <Icon icon="ArrowLeft" size="S" />
            </Button>
          </div>
          <div className={styles.idDateWrapper}>
            <Typography variant="h500-heavy" component="p">
              <LocaleAsString id="my_account.menu.order" />#{selectedOrder.id}
            </Typography>
          </div>
          <div className={styles.priceWrapper}>
            <Typography
              variant="h400-medium"
              component="p"
              className={styles.date}
            >
              {selectedOrder.formatDate}
            </Typography>
            <Currency
              price={selectedOrder.orderTotalPrice}
              currency={selectedOrder.orderCurrency}
              template="myAccountOrderTotal"
              className={styles.price}
            />
          </div>
        </div>
        <section className={styles.section}>
          <p className={styles.description}>
            <Typography variant="h500-medium" component="span">
              <LocaleAsString id="my_account.passholders.assigned.define" />
            </Typography>
          </p>
          <div className={styles.quantitySelector}>
            {hasAdults && (
              <div className={styles.selector}>
                <Typography variant="h400-medium" component="span">
                  <LocaleAsString id="my_account.passholders.assigned.adult" />
                </Typography>
                <QuantityPicker
                  quantity={adults}
                  setQuantity={setAdults}
                  maxQuantity={product.maxAdult * product.quantity}
                  minQuantity={product.minAdult * product.quantity}
                />
              </div>
            )}
            {hasAdults && hasChildren && <div className={styles.divider} />}
            {hasChildren && (
              <div className={styles.selector}>
                <Typography variant="h400-medium" component="span">
                  <LocaleAsString id="my_account.passholders.assigned.junior" />
                </Typography>
                <QuantityPicker
                  quantity={children}
                  setQuantity={setChildren}
                  maxQuantity={product.maxJunior * product.quantity}
                  minQuantity={product.minJunior * product.quantity}
                />
              </div>
            )}
          </div>
        </section>

        {passholders.map((holder, idx) => {
          const { title } = holder;

          const holderData = { ...holder };

          holderData.title = title;

          const saved = savedPassholders.find((savPass) => savPass.id === idx);
          return (
            <Passholder
              friendsAndFamily={friendsAndFamily}
              passholder={holderData}
              savedPassholder={saved}
              handleChange={handleChange}
              focus={focus === idx}
              requirements={requirements}
              loadingImage={loadingImage}
              errorImage={errorImage}
              key={`passholders_passholder_${idx}`}
            />
          );
        })}

        <div className={styles.section}>
          <Button
            className={styles.passholderCTA}
            contentClassName={styles.passholderCTAContent}
            onClick={() => assignPassholderModal()}
            disabled={checkPassholders()}
          >
            <Typography
              variant="h400-medium"
              component="span"
              className={styles.label}
            >
              <LocaleAsString id="my_account.passholders.proceed_to_confirmation" />
            </Typography>
          </Button>
        </div>
      </div>
      {assignModal && (
        <Modal closeFn={() => closeModalFunction()} isAlert={true} size="small">
          {!successModal ? (
            <div className={styles.assignModal}>
              <div className={styles.iconWrapper}>
                <Icon
                  icon="AlertTriangle"
                  size="Medium"
                  className={styles.iconRed}
                />
              </div>
              <div className={styles.infoWrapper}>
                <Typography
                  variant="h400-heavy"
                  component="span"
                  className={styles.confirmTitle}
                >
                  <LocaleAsString id="my_account.passholders.confirm" />
                </Typography>

                <Typography
                  variant="h300-medium"
                  component="span"
                  className={styles.confirmEdit}
                >
                  <LocaleAsString id="my_account.passholders.confirm.edit" />
                </Typography>
                <Typography
                  variant="h300-medium"
                  component="span"
                  className={styles.informationCorrect}
                >
                  <LocaleAsString id="my_account.passholders.information.correct" />
                </Typography>
              </div>
              <div className={styles.buttonsWrapper}>
                <Button
                  onClick={() => closeModalFunction()}
                  className={styles.buttonBack}
                >
                  <Icon icon="ArrowLeft" className={styles.icon} size="S" />
                </Button>
                <Button
                  onClick={() => successPassholderModal()}
                  className={styles.cta}
                  loading={assignLoading}
                >
                  <Typography variant="h400-medium" component="span">
                    <LocaleAsString id="my_account.passholders.confirm" />
                  </Typography>
                </Button>
              </div>
            </div>
          ) : (
            <div className={`${styles.assignModal} ${styles.successModal}`}>
              <div className={styles.iconWrapper}>
                <Icon
                  icon="CheckCircle"
                  size="Medium"
                  className={styles.icon}
                />
              </div>
              <Typography
                variant="h400-heavy"
                component="span"
                className={styles.infoAssigned}
              >
                <LocaleAsString id="my_account.passholders.assigned.successfull" />
              </Typography>
              <Button
                onClick={() => closeModalFunction()}
                className={styles.cta}
              >
                <Typography variant="h400-medium" component="span">
                  Ok
                </Typography>
              </Button>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default Passholders;
