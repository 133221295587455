const MenuOptions = [
  {
    label: "my_account.menu.orders",
    icon: "File",
    action: "orders",
  },
  {
    label: "my_account.menu.details",
    icon: "User",
    action: "guestdetails",
  },
  {
    label: "my_account.menu.password",
    icon: "Lock",
    action: "changepassword",
  },
];

export { MenuOptions };
